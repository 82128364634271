import { combineReducers } from 'redux';
import { somethingIsLoading } from './loading';
import { demo_reducer, IDemoState } from './demo'

export interface IOverallState {
    demo: IDemoState,
    loading: boolean
}

const rootReducer = combineReducers({
    demo: demo_reducer,
    somethingIsLoading: somethingIsLoading
});

export default rootReducer;