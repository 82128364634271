import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';

export class Attributes extends Component {
    static displayName = Attributes.name;

    render() {
        return (
            <Article title="Attributes" aside="Programming">

                <Helmet>
                    <title>Attributes - Abodit NLP</title>
                    <meta name="description" content="Abodit NLP uses attributes to add meaning to methods and their parameters." />
                </Helmet>

                <p>The following attributes are used by the Abodit NLP system:</p>
                <h3 id="attributes-on-methods">Attributes on methods</h3>

                <h4 id="-aboditnlp-attributes-priority-"><code>AboditNLP.Attributes.Priority</code></h4>
                <p>Place a priority attribute on any NLP rule to affect the priority with which rules are recognized in the event of an ambiguous rule match.</p>

                <h4 id="-aboditnlp-attributes-productionrule-"><code>AboditNLP.Attributes.ProductionRule</code></h4>
                <p>Place a production rule attribute on a method to declare a production rule.  You can set a priority and an associativity to handle
            precedence rules.  For example, to define expressions you would use an associativity of Left and a priority for <code>*</code>
                that is higher than that for <code>+</code>.
            <br />
            Methods in the same class are compared by priority. Methods in different classes are not compared.
            <br />
            You can also set a probability on a production rule which is used in the probabilistic parser to influence
            which path is taken when there are multiple ambiguous parses.
</p>

                <h3 id="attributes-on-method-parameters">Attributes on method parameters</h3>
                <h4 id="-aboditnlp-attributes-insensitive-"><code>AboditNLP.Attributes.Insensitive</code></h4>
                <p>Place this on a parameter in an action method and set parameters for case-sensitivity and edit-distance to
enable (or disable) approximate matching and spelling correction. By default case-insensitive matching is enabled.</p>
                <h4 id="-aboditnlp-attributes-optional-"><code>AboditNLP.Attributes.Optional</code></h4>
                <p>Place this on parameters in a rule that are optional tokens,
e.g. <code>...(Pronoun.I i, IVerb v, [Optional]Article.the the, ...)</code>.</p>
                <h4 id="-aboditnlp-attributes-permute-"><code>AboditNLP.Attributes.Permute</code></h4>
                <p>Place this on parameters in a rule that can appear in any order,
e.g. <code>...(... [Permute]Where where, [Permute]TemporalSet when, ...)</code>. You can also add an integer group number
to create permutation groups.</p>
                <h4 id="-aboditnlp-attributes-pasttense-futuretense-presenttense-singular-plural-"><code>AboditNLP.Attributes.PastTense</code>, <code>FutureTense</code>, <code>PresentTense</code>, <code>Singular</code>, <code>Plural</code></h4>
                <p>Place these on parameters in a rule to constrain verbs to a given tense and nouns to a given singular/plural.
For example, a rule asking for <code>Verb.catch1</code> will match &quot;catch&quot;, &quot;catches&quot;, &quot;caught&quot;, &quot;catching&quot;, ... but if
you add the attribute <code>PastTense</code> you can make it match only &quot;caught&quot;.
                </p>

                <h3>Explanation</h3>
                <p>These attributes applied together allow a single method or a small group of methods to handle
                thousands of possible ways to say the same thing. Instead of having to define a rule for each
                permutation and for each different way of expressing the same thing you can create a couple
                of production rules, add some optional and permute attributes and express all the ways a user
                    could say something.</p>

            </Article>
        );
    }
}