import React, { Component } from 'react';
import { Nav, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Aside extends Component {
  static displayName = Aside.name;

  constructor(props) {
    super(props);
    this.state = {
      title: props.title
    };
  }

  render() {
    if (this.state.title === "Download")
    {
      return (
        <aside id="sidebar" className="widget col-md-3">
        <h3>Download</h3>
        <p>This Natural Language engine is available today for download and integration in your.NET projects. </p>
        <p>
          You can add it to your application using Nuget where you'll find three packages: one for 
          units of measure (including time/date expressions), one containing the engine itself and one 
          containinga large dictionary and thesaurus. 
          It is recommended that you take a look at the <Link to="/home/download">demonstration project</Link> first.
        </p>
        </aside>
        );
    }
    else if (this.state.title === "Licensing")
    {
      return (
        <aside id="sidebar" className="widget col-md-3">
        <h3>Licensing</h3>
        <p>For personal, non-commercial projects there is no charge.  For use in commercial applications and for any 
          consulting requirements please <a href="mailto:ian@abodit.com">contact me</a>.</p>
        </aside>
        );
    }
    else if (this.state.title === "Applications") {
      return (
        <aside id="sidebar" className="widget col-md-3">
        <h3>Applications</h3>
        <Nav vertical>
          <NavLink tag={Link} to="/applications/sales">Sales</NavLink>
          <NavLink tag={Link} to="/applications/inventory">Inventory</NavLink>
          <NavLink tag={Link} to="/applications/customer-service">Customer Service</NavLink>
          <NavLink tag={Link} to="/applications/home-automation">Home Automation</NavLink>
        </Nav>
        </aside>
        );
    }
    else if (this.state.title === "Features") {
      return (
        <aside id="sidebar" className="widget col-md-3">
        <h3>Features</h3>
        <Nav vertical>
          <NavLink tag={Link} to="/features/conversational">Conversational</NavLink>
          <NavLink tag={Link} to="/features/lexemes">Lexemes</NavLink>
          <NavLink tag={Link} to="/features/grammar">Grammar</NavLink>
          <NavLink tag={Link} to="/features/sentences">Sentences</NavLink>
          <NavLink tag={Link} to="/features/word-network">Word network</NavLink>
          <NavLink tag={Link} to="/features/wordnet">Wordnet</NavLink>
          <NavLink tag={Link} to="/features/strongly-typed">Strongly-typed</NavLink>
          <NavLink tag={Link} to="/features/metamodel">Metamodel</NavLink>
          <NavLink tag={Link} to="/features/transport">Transport</NavLink>
          <NavLink tag={Link} to="/features/nlg">NLG</NavLink>
        </Nav>
        </aside>
        );
    }
    else if (this.state.title === "Database Query") {
      return (
        <aside id="sidebar" className="widget col-md-3">
        <h3>Features</h3>
        <Nav vertical>
          <NavLink tag={Link} to="/features/conversational">3 products</NavLink>
          <NavLink tag={Link} to="/features/lexemes">3 </NavLink>
          <NavLink tag={Link} to="/features/grammar">Grammar</NavLink>
          <NavLink tag={Link} to="/features/sentences">Sentences</NavLink>
          <NavLink tag={Link} to="/features/word-network">Word network</NavLink>
          <NavLink tag={Link} to="/features/wordnet">Wordnet</NavLink>
          <NavLink tag={Link} to="/features/strongly-typed">Strongly-typed</NavLink>
          <NavLink tag={Link} to="/features/metamodel">Metamodel</NavLink>
          <NavLink tag={Link} to="/features/transport">Transport</NavLink>
          <NavLink tag={Link} to="/features/nlg">NLG</NavLink>
        </Nav>
        </aside>
        );
    }
    else if (this.state.title === "Programming") {
      return (
        <aside id="sidebar" className="widget col-md-3">
        <h3>Programming</h3>
        <Nav vertical>
          <NavLink tag={Link} to='/programming/overview'>Overview</NavLink>
          <NavLink tag={Link} to='/programming/dependency-injection'>Dependency Injection</NavLink>
          <NavLink tag={Link} to='/programming/production-rules'>Production Rules</NavLink>
          <NavLink tag={Link} to='/programming/startup'>Startup</NavLink>
          <NavLink tag={Link} to='/programming/attributes'>Attributes</NavLink>
          <NavLink tag={Link} to='/programming/features'>Features</NavLink>
          <NavLink tag={Link} to='/programming/lexemes'>Lexemes</NavLink>
          <NavLink tag={Link} to='/programming/meta-models'>Meta models</NavLink>
          <NavLink tag={Link} to='/programming/production-rules'>Production Rules</NavLink>
          <NavLink tag={Link} to='/programming/rules'>Rules</NavLink>
          <NavLink tag={Link} to='/programming/temporalsets'>Temporal sets</NavLink>
          <NavLink tag={Link} to='/programming/tokens'>Tokens</NavLink>
        </Nav>
        </aside>
        );
    }
    else if (this.state.title === "Home Automation") {
      return (
        <aside id="sidebar" className="widget col-md-3">
        <figure>
            <img src="/Content/home-automation.png" alt="Home automation with natural language processing (NLP)" class="thumbnail alignleft" />
        </figure>
        <p>
          AboditNLP makes it easy to develop chat bots that control home automation systems
          because each rule can use strongly-typed values that represent actual
          objects in the home. An interface `Light` for example might be connected to a Token factory
          that looks up lights within the home. When a sentence is created that expects a `Light` object,
          the particular light mentioned by the user is passed in to the rule method. 
        </p>
      </aside>
      );
    }
    else {
      return (
        <aside id="sidebar" className="widget col-md-3">
        <h3>Download</h3>
        <p>This Natural Language engine is available today for download and integration in your.NET projects. </p>
        <p>
          You can add it to your application using Nuget where you'll find three packages: one for 
          units of measure (including time/date expressions), one containing the engine itself and one 
          containinga large dictionary and thesaurus. 
          It is recommended that you take a look at the <Link to="/home/download">demonstration project</Link> first.
        </p>
        <h3>Licensing</h3>
        <p>For personal, non-commercial projects there is no charge.  For use in commercial applications and for any 
          consulting requirements please <Link to="/home/contact">contact me</Link>.</p>
        </aside>
        );
      
    }
  }
}
