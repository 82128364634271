import React, { Component } from 'react';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="navbar fixed-bottom footer">
                <div className="col-md-1 row center-vertically"></div>
                <div className="col-md-10 row center-vertically">
                    <p> </p>
                    <p>© Ian Mercer 2011-2021</p>
                </div>
                <div className="col-md-1 row center-vertically"></div>
            </div>
        );
    }
}