import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Article } from './Article'

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
<Article title="Natural Language for .NET">

<p>The Abodit Natural Language Engine for .NET lets you add natural language
understanding features to your software or website. These features can be as simple as 
a text input field where you want to parse dates and times written in plain english 
(e.g. <code>next friday afternoon</code>) or as complex as a full
chat feature with a <Link to="../features/conversational">conversational</Link> flow.</p>

<p>This natural language engine is designed for command, control and 
  query <Link to="../applications/sales">applications</Link>.</p>

<p>Abodit NLP offers several unique benefits over other approaches to natural language understanding:</p>
<ol>
<li><p>Writing rules is as simple as writing C# methods. No separate configuration is needed and the
parameters passed are useful, strongly-typed objects like times, dates, distances, ...</p>
</li>
<li><p>A large variety of units of measure and temporal expressions are provided. Temporal expressions
may be as simple as <code>next monday</code> or complex as <code>last year on a friday in May between 5pm and 6pm</code>.</p>
</li>
<li><p>Rules are created in terms of <em>meanings</em> not <em>words</em>. A single meaning may encompass many different words
that are synonyms. You even get intellisense support for meanings so you can hover over a parameter type
and see a short description of the group of words it represents.</p>
</li>
<li><p>Rules can also use <em>classes of meanings</em>. This means you can write a single rule that
recognizes, say, all mammals instead of having to write a rule for each individual mammalian name. Extending this
to your own domain might allow a single rule that recognizes any product by name.  </p>
</li>
<li><p>Verbs and nouns are not just words with attached meanings, they are part of a graph of words and meanings.
Verbs can be conjugated (changed into a different tense), and nouns can be turned into their singular, plural or possessive forms.
This makes it easy to respond to users using the words they used, for example the user types 
<code>add a task for today</code> and the system responds <code>I added a task, you now have 7 tasks</code>.</p>
</li>
<li><p>Word delimiters (spaces) are mostly optional allowing users to runwordstogethertwitterstyle and still
be recognized.</p>
</li>
<li><p>The engine is agnostic as to the transport used: it can support any form of text input (web, chat, SMS, ...)
and any form of output including multi-modal output where, say, a web page is updated in response to
user input.</p>
</li>
<li><p>The engine is agnostic as to the dialog approach used: it can support single query/response flows with no memory,
query/response flows with history, frame-filling flows, state-machine based flows, ... Examples are provided
for some of these but you can build your own too using database or in-memory storage for the user&#39;s context,
conversation history, ...</p>
</li>
</ol>

<p>The emphasis in all of this is on making it incredibly easy for you to add new sentence rules and to do so in a strongly-typed way.  There is no XML to edit and no other
configuration required to add a sentence rule.  All you do is add a new method, specify the parameters you want to see and the library does the rest.</p>
<p>Once you have these in place it&#39;s simply a matter of hooking the NLP engine up to whatever user interface <Link to="/features/transport">transports</Link> you have chosen, e.g. email, SMS, chat.</p>

<p>Abodit NLP does not do sentiment analysis, named entity recognition or part of speech tagging. These traditional
  NLP tasks are either statistical (e.g. sentiment = good, 75% chance), or are just a small part of understanding a sentence
  and acting on it. As such they aren't suitable for the command, control or query applications for which Abodit NLP was designed.
</p>

</Article>
    );
  }
}
