import React, { Component } from 'react';
import { Article } from '../components/Article';

export class Inventory extends Component {
  static displayName = Inventory.name;

  render () {
    return (
      <Article title="Inventory" aside="Applications">
<p>
  Abdodit NLP can be built into your existing line of business applications such as 'inventory' to give english
  language query capabilities. 
</p>

<p>
  Queries generated from english language expressions can be transformed into SQL, MongoDB or LINQ Expressions
  allowing querying to happen in the database or in memory, or both. 
</p>

<p>
  Abodit NLP includes query capabilities that include units of measure (e.g. querying in centimeters against a database
  with values stored in milimeters), calculate fields (e.g. area = width x length), currency (e.g. $ vs Euros),
  and complex data-time expressions.
</p>

</Article>
    );
  }
}
