import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export class Lexemes2 extends Component {
    static displayName = Lexemes2.name;

    render() {
        return (
            <Article title="Lexemes" aside="Programming">

                <Helmet>
                    <title>Programming with Lexemes - Abodit NLP</title>
                    <meta name="description" content="Abodit NLP works with meanings not just words." />
                </Helmet>

                <p>A Lexeme is a unit of meaning. One or more words can be attached to that meaning, these words are synonyms.</p>

                <h3>WordNet, a large dictionary and thesaurus</h3>
                <p>The AboditNLP assembly includes a small set of words and meanings; you can add your own dictionary containing
                just the words you need but you can also install one or both of the provided Wordnet packages:
                <code>AboditNLP.Wordnet</code> and <code>Abodit.Wordnet.Extended</code>. The former contains common words,
                the latter less common
words. All animal names, plant names and genus names for example are in the latter.</p>
                <p>In English, one meaning may be a subclass of another, or it might be an instance of a class. For
example, <code>labrador</code> is a <code>Dog</code> and <code>Dog</code> is a <code>Mammal</code>.
But these meanings don&#39;t necessarily form a simple tree so
a normal C# class  hierarchy cannot be used to model them. Instead, Abodit NLP uses C# interfaces
to model meanings. This allows for multiple-inheritance.
One remaining issue is the ability to distinguish between the word &#39;mammal&#39;
and the class of all &#39;mammal&#39; words: by convention AboditNLP uses lower-case interface names
to indicate specific instances of a word or meaning and capitalized interface names to indicate
a class of meanings.</p>
                <p>So <code>Noun.dog</code> is the word &quot;dog&quot; and any synonyms of &quot;dog&quot; (domestic dog, Canis familiaris).
                <code>Noun.Mammal</code> is the class of all mammals and can recognize <i>any</i> word that is a mammal
                (dog, horse, giraffe, pig, goat, whale, ...)
                but <code>Noun.mammal</code> is the word &quot;mammal&quot; and any synonyms of it.</p>
                <p>You can see the interfaces that have been created for words by visiting the
                demo page on this site. Type &quot;define mammal&quot; and you&#39;ll see all of the meanings that &quot;mammal&quot; can have
and for each you will see the interfaces that it has.</p>
                <p>These interface names don&#39;t include the customary initial letter &#39;I&#39; because that would be cumbersome
and confusing. They do include a number on the end to distinguish the different meanings one word can have.</p>

                <h3>Adding your own words and meanings</h3>
                <p>When you add your own words you&#39;ll probably want to use the same namespaces: <code>Noun</code>, <code>Verb</code>, <code>Adjective</code>, ...</p>
                <p>You can use inheritance on these to define classes of words that you want to recognize
in one group, e.g. all positive adjectives.</p>
                <p>If you add any Token classes these should also inherit the appropriate interface <code>INoun</code>, <code>IVerb</code>, <code>IAdjective</code> etc.</p>
                <p>Nouns are further sub-classed into <code>singular</code> and <code>plural</code>, verbs by tense and adjectives by <code>comparative</code> or <code>superlative</code>.</p>
                <SyntaxHighlighter style={docco} language="csharp">
                    {`namespace Noun
{
    public interface dog : INoun { }
    // You can also distinguish between singular or plural nouns
    // by using interface inheritance like this:
    public interface cat : Noun.Type.Singular { }
    public interface cats : Noun.Type.Plural { }
}`}</SyntaxHighlighter>
                <p>When it comes to verbs you might want to define specific tenses of them, or have some catch-all interface that handles
any tense of a given verb (or verb synset)</p>
                <SyntaxHighlighter style={docco} language="csharp">{`namespace Verb
{
    public interface report : Verb.Tense.Present { }
    public interface reported : Verb.Tense.Past { }
}`}</SyntaxHighlighter>
                <p>Use interface inheritance to group your meanings into single interfaces that can be recognized by a single rule.</p>
                <SyntaxHighlighter style={docco} language="csharp">
                    {`public interface CompanyName : ProperNoun { }
public interface Microsoft : CompanyName { }
public interface Google : CompanyName { }
public interface Facebook : CompanyName { }`}</SyntaxHighlighter>
                <p>Having defined all the meanings that you want to use in your application you now need to define
the words that have those meanings. You do this using a class that implements <code>ILexemeGenerator</code> like the
one below:</p>
                <SyntaxHighlighter style={docco} language="csharp">
                    {`public class SampleWords : ILexemeGenerator
{
    public void CreateWords(ILexemeStore store)
    {
        store.Store(Lexeme.New.Noun("hello", typeof(Noun.hello)));
        store.Store(Lexeme.New.Noun("world", typeof(Noun.world)));
        store.Store(Lexeme.New.Noun("goodbye", typeof(Noun.goodbye)));
        store.Store(Lexeme.New.Noun("bye", typeof(Noun.goodbye)));

        store.Store(Lexeme.New.Verb("report", typeof(Verb.report), typeof(Verb.Tense.Present), typeof(Verb.Type.Transitive))
        // If you need to override an irregular verb, or add an interface to a specific tense you can do that
        .Past("reported", typeof(Verb.reported))
        // Verbs can have associated Noun forms, Adverb forms and Adjective forms, e.g. when you report something the thing is a &#39;report&#39;
        .Noun("report", typeof(Noun.report)));

        ...
    }
}`}</SyntaxHighlighter>
                <p>Each lexeme is define using a fluent builder interface that can build graphs of words.The graph allows you to
                convert between singular and plural forms, between past and present tenses etc.The graph also
represents synonym, antonym, holnym and meronym relationships.</p>
                <p>When you add a word to the store, AboditNLP will automatically calculate the plural form of it, and will conjugate
                regular verbs for you. Of course the English language is never that simple so if you need to add irregular forms
because the plural of &quot;octopus&quot; is &quot;octopi&quot;, &quot;octopuses&quot; or &quot;octopodes&quot; you can do that too.</p>
                <p>For each word you add to the dictionary you can attach any number of interfaces although you&#39;d normally only
attach one as you can represent the others through interface inheritance.</p>

                <h3 id="classes-instead-of-interfaces">Classes instead of interfaces</h3>
                <p>When you retrieve a lexeme that was created using a list of interfaces, a dynamic object is created
                with all of those interfaces applied to it. Sometimes however you&#39;ll want to store your own objects in
the lexeme store with additional data, either a real-active object or just an id for a database object.</p>
                <p>To do this you can inherit from one of the Lexeme classes and set the <code>Value</code> property on it.</p>
                <p>For example, here&#39;s a class defined to hold a reference to a <code>HouseBaseObject</code>:</p>
                <SyntaxHighlighter style={docco} language="csharp">
                    {`public class HouseLexeme : LexNoun
{
    public HouseLexeme(string text, HouseBaseObject houseBaseObject)
        : base(SynSet.Get(houseBaseObject.UniqueID), text)
    {
        this.Value = houseBaseObject;
    }
}`}</SyntaxHighlighter>
                <p>And the corresponding <code>LexemeGenerator</code>:    </p>
                <SyntaxHighlighter style={docco} language="csharp">
                    {`public class HouseObjectLexemeGenerator : ILexemeGenerator
{
    private readonly IHouse house;
    public HouseObjectLexemeGenerator(IHouse house)
    {
        this.house = house;
    }

    public void CreateWords(ILexemeStore store)
    {
        foreach (var houseObject in house)
        {
            store.StoreSingleLexeme(new HouseLexeme(houseObject.Name, houseObject));
        }
    }
}`}</SyntaxHighlighter>
                <p>This places every house object into the LexemeStore at start-up but you can also dynamically
add new lexemes at runtime by using the <code>LexemeStore</code> property on an instance of <code>NLP</code>.</p>

                <h3>Using an ontology specified in Turtle</h3>
                <p>You can also specify your dictionary and meanings using RDF in Turtle format. Please contact us
                    for details if you want to do this.</p>

                <code>
                    ab:synset-metamodel-noun-1 wn20schema:senseLabel "metamodel"@en-us .
                    ab:synset-metamodel-noun-1 wn20schema:senseLabel "meta model"@en-us .
                    ab:synset-metamodel-noun-1 wn20schema:gloss "Data describing a data model, e.g. the contents and types for each column in a table"@en-us .
                </code>

            </Article>
        );
    }
}