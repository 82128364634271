import React, { Component } from 'react';
import { Article } from '../components/Article';

export class Features extends Component {
  static displayName = Features.name;

  render () {
    return (
      <Article title="Features">
      </Article>
     );
  }
}
