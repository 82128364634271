import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import { Link } from 'react-router-dom';

export class Tokens extends Component {
  static displayName = Tokens.name;

  render () {
    return (
      <Article title="Tokens" aside="Programming">

<Helmet>
  <title>Tokens - Abodit NLP</title>
  <meta name="description" content="Abodit NLP understand many different token types and you can add your own too" />
</Helmet>

<p>Abodit NLP and the Abodit Units assembly provides many different, predefined tokens that it knows how to parse.</p>
<p>Here are some of them with examples:</p>
<ul>
<li><code>TokenInt</code>                : 17</li>
<li><code>TokenDouble</code>             : 123.5</li>
<li><code>TokenNumericExpression</code>  : 12 * 7 + 89</li>
<li><code>Cardinal</code>                : 17, seventeen</li>
<li><code>Ordinal</code>                 : 17th, seventeenth</li>
<li><code>Rational</code>                : two eighths, 4/5</li>
<li><code>Distance</code>                : 12 miles, 23.5km, ...</li>
<li><code>Time</code>                    : 34 hours and 10 minutes</li>
<li><code>TimePeriod</code>              : 3 hours 4 minutes, 17 years</li>
<li><code>TemporalSet</code>             : 3 weeks ago, yesterday, the last friday in May</li>
</ul>
<p>TemporalSets are a powerful set of classes that can represnt most common (and many uncommon) expressions
for times and dates. See <Link to="/programming/temporalsets">Temporal Sets</Link> for more details.</p>
<p>You can also create your own token classes and your own token factories (by implementing <code>ITokenFactory</code>).</p>
<p>A token factory can parse the input from the current location using string comparisons, regexs or
database lookups and can then return one or more instances of that type. The token factory
should be labelled with a <code>Produces</code> attribute listing the types of object it can produce.
Take a look in the demo project for examples of how to do this.</p>
<p>In addition to Tokens you can also add new words to the built-in 
dictionaries <Link to="/programming/lexemes">see Lexemes</Link> and you can 
add <Link to="/programming/production-rules">production rules</Link> that can take a sequence of tokens
and reduce it to a new single token. Abodit NLP uses such production rules extensively internally to,
for example, convert three TokenInts separate by <code>/</code> characters into a date if those ints form a valid
date.</p>
      </Article>
    );
  }
}
