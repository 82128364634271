import { SeverityLevel, ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

class AI {
    constructor() {
        this._type = 'AI';

        this.reactPlugin = new ReactPlugin();

        const browserHistory = createBrowserHistory({ basename: '' });

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: '7c9a3614-fb9c-4f7d-9b6b-a75b22cd6f31',
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
    }

    getAppInsights() {
        return this.appInsights;
    }

    trackException(message) {
        this.appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error });
    }

    trackTrace(message) {
        this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information });
    }

    trackEvent(event) {
        this.appInsights.trackEvent({ name: event });
    }
}

export default new AI();