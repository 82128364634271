import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Link } from 'react-router-dom';

export class Startup extends Component {
    static displayName = Startup.name;

    render() {
        return (
            <Article title="Startup" aside="Programming">

                <Helmet>
                    <title>Startup - Abodit NLP</title>
                    <meta name="description" content="To initialize Abodit NLP you need to register any rules and tokens you want it to recognize" />
                </Helmet>

                <p>After you have registered all of your rules and token factories with AboditNLP by using
  a <Link to="dependency-injection">dependency injection container</Link> it&#39;s time to start the engine.</p>
                <p>But first we need to set the dependency injection resolver that AboditNLP will use to resolve
                dependencies for the classes containing your rules and token factories. For Autofac
a resolver is provided. It takes the built IOC container as a parameter:</p>

                <SyntaxHighlighter style={docco} language="csharp">{`NLP.SetResolver(new AboditNLP.IoC.Autofac.AutofacResolver(container));`}</SyntaxHighlighter>

                <p>You must also register loggers using `Microsoft.Extensions.Logging.ILogger`. When you resolve `INLP` it will
                request an instance of `ILogger&lt;NLP&gt;`.
            </p>

                <p>Finally, you are now ready to get an instance of the NLP engine and start it up. Note the use of the container
to resolve <code>INLP</code>. This allows the NLP engine to get access to all of the injected rules and token classes.</p>
                <SyntaxHighlighter style={docco} language="csharp">
                    {`var nlp = container.Resolve<INLP>();
nlp.InitializeAsync();`}</SyntaxHighlighter>

                <p>If you need access to the engine in other Web/WebApi controllers, chat handlers, Slack bots, ... you can
simply take a dependency on <code>INLP</code> to get the singleton engine instance registered with your container.</p>
            </Article>
        );
    }
}