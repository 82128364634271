import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';

export class Overview extends Component {
  static displayName = Overview.name;

  render () {
    return (
      <Article title="Overview" aside="Programming">

<Helmet>
  <title>Programming overview - Abodit NLP</title>
  <meta name="description" content="Abodit NLP makes natural language understanding easy from C#." />
</Helmet>

<p>The best way to get started is to download the sample application from Github and explore that, but
  first let's look at how you can use Abodit NLP in your own applications. </p>

<p>After including the Abodit NLP packages in your project you need to perform the following steps:</p>

<ol>
<li>Configure <Link to="dependency-injection">dependency injection</Link></li>
<li>Write <Link to ="rules">rule</Link> methods to define concepts you want to parse and/or methods you want to execute in response to user input</li>
<li>Optionally write <Link to ="production-rules">production rules</Link> to define a richer 'grammar' for your project</li>
<li>Call the Initialize method to initialize the engine asynchronously</li>
<li>Call either the <code>Execute</code> or the <code>Parse</code> methods to parse your user's input</li>
</ol>

      </Article>
     );
  }
}
