import React, { Component } from 'react';
import { Article } from '../components/Article';
import { Link } from 'react-router-dom';

export class HomeAutomation extends Component {
  static displayName = HomeAutomation.name;

  render () {
    return (
      <Article title="Home Automation" aside="Applications">
<p>With this library you can build a chat interface to your home that you can control from anywhere in the world.
 Your house can chat to you to tell you what's going on and you can ask it questions or tell it what to do.
 And because this library can work with any <Link to="/features/transport">transport</Link> including email, SMS,
 XMPP, Telegram, ... you don't even need to install any extra apps on your phone.</p>
<p>Examples of things you can do when you connect a home automation system to this natural language engine can be seen in this
actual dialog with my own home automation system:-</p>
<pre><code>
{'User    basement off\n \
House   I turned the lights in the basement off\n \
\n \
User:   who called yesterday?\n \
House:  Spam call from TV Survey (2025551212) at 3:27 PM\n \
\n \
User:   is the barn occupied?\n \
House:  No, nobody has been in the barn since yesterday at 6:37 PM\n \
\n \
User:   back lawn on\n \
House:  I turned Back lawn sprinklers on\n \
\n \
User:   kitchen 70F\n \
House:  I turned the kitchen thermostat to 70F\n \
\n \
User: play 80s or 90s in office\n \
House: Queue\'d up 4388 songs matching 80\'s or 90\'s\n \
House: ¶ Mike + The Mechanics - Why Me? in Office\n \
'}
</code></pre>
<p>And the code to build a chat system like this is very easy to write because Abodit NLP provides 
  a <Link to="/features/strongly-typed">strongly-typed</Link> interface. So a rule expecting an <code>IRoom</code> object
  is handed an actual live-object that it can then act on. Because the library runs locally as part of your system it
  has full access to your object model and your local databases.</p>
</Article>
    );
  }
}
