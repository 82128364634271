import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import { Link } from 'react-router-dom';

export class Conversational extends Component {
  static displayName = Conversational.name;
  
  render() {
    return (
    <Article title="Conversational" aside="Features">

<Helmet>
    <meta name="description" content="Conversational natural language engine" />
    <title>Conversational natural language engine</title>
</Helmet>

<p>A <em>conversational natural language engine</em> allows humans and computers to converse in a natural way using typed messages.
These messages may be exchanged using <Link to="/features/transport">SMS, XMPP chat, email, or web - based chat</Link>.</p>
<p>Conversational interfaces are an improvement over form-filling interfaces for many applications.  One of the best-known examples
of this is the input box on Google calendar where you can type something like <em> &#39;10:20 meeting at Larry&#39;s&#39;</em> and it will create 
a meeting starting at 10:20 and will set the location to <em> &#39;Larry&#39;s&#39;</em>.  This particular example is a hybrid interface 
where a conversational element has been integrated into a traditional form-based interface. A pure conversation interface is 
often called a &#39;chatbot&#39;.</p>

<p>Conversational interfaces are particularly well suited to mobile applications where the small screen,
 tiny keyboard and lack of a mouse makes traditional form-filling a tedious and error prone experience.</p>
<p>Consider for example the simple request <code>what orders did we receive last month on a friday after 4pm</code>.  What would the dialog look like
to specify a query like that?  What if, instead, your users could simply enter what they are looking for?  That&#39;s what a
conversational natural language engine can do for your business.</p>

<p>A conversational interface has the following key features:</p>
<ul>
<li>Each party can initiate or respond to a message at any time</li>
<li>Messages are not treated in isolation but understood in the overall context of the conversation including a complete history of previous messages sent or received.</li>
</ul>
<p>Support for conversations is provided through &#39;Memory&#39; objects that can be used to remember the state of a conversation and other objects that have been mentioned recently.</p>
<p>Techniques are also provided so that the computer can ask a question (e.g. enter your PIN number) and then, when the user enters a number, it can pair their entry up
with the code to deal with it.</p>
</Article>
    );
  }
}
