import * as ACTION from './actionTypes';

export function updateTable(value = {}) {
    return {
        type: ACTION.TABLE_UPDATE,
        value: value
    };
}

export function tableLoading() {
    return {
        type: ACTION.TABLE_UPDATE,
        value: { id: -2, name: "p", properties: {}, children: [{ id: -2, name: "plain", text: "loading...", properties: {}, children: [] }] }
    }
}

export function updateInput(value = '') {
    return {
        type: ACTION.INPUT_UPDATE,
        value: value
    };
}

export function updateExpression(value = '') {
    return {
        type: ACTION.EXPRESSION_UPDATE,
        value: value
    };
}

export function updatePlaceholder(value = '') {
    return {
        type: ACTION.PLACEHOLDER_UPDATE,
        value: value
    };
}