import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Link } from 'react-router-dom';

export class Metamodels extends Component {
    static displayName = Metamodels.name;

    render() {
        return (
            <Article title="Meta models" aside="Programming">

                <Helmet>
                    <title>Metamodels - Abodit NLP</title>
                    <meta name="description" content="Abodit NLP works with your databases through a meta model describing them" />
                </Helmet>

                <p>Another powerful feature is the ability to define a meta-model for a database table (or other data source)
                and to turn queries into SQL (or other language) queries. You don't even need to have a database backing the
                meta-model, you can use it was a way to describe your domain and have questions about your domain
                parsed automatically.
</p>

                <p>A meta-model defines the english language terms used to represent each database table and each of the columns
in that table. Simply saying that a column is called <code>PWeight</code> and has a type
of <code>double</code> isn&#39;t sufficient to
build Natural Language queries against the data. Instead, if you define a meta-model that specifies that
the <code>PWeight</code> column is a <code>double</code> representing a <code>weight</code> measured
in <code>grammes</code>, this allows Abodit NLP to understand english queries like:</p>

                <SyntaxHighlighter style={docco} language="plaintext">{`weight is over 300kg
weight is under 30oz
heaviest
lightest
between 3 and 30lbs`}</SyntaxHighlighter>

                <p>Abodit NLP creates <code>sort</code> and <code>where</code> clauses for SQL (or other query languages) from
each of these english language expressions.</p>
                <p>And since Abodit NLP also understands <Link to="/programming/temporalsets">temporal sets</Link> you can include datetime
fields in your metamodel like <code>DateAdded</code>, <code>DateSold</code>, ... and Abodit NLP can then create efficient SQL queries
for english language inputs like:</p>

                <SyntaxHighlighter style={docco} language="plaintext">
                    {`sold last week
added in May or June last year in the afternoon
sold on a Friday evening
...`}</SyntaxHighlighter>

                <p>Queries can of course combine multiple fields, a sort order, 'and', 'or' and 'not' logic and a limit on how many records to return.</p>
                <SyntaxHighlighter style={docco} language="plaintext">
                    {`5 houses priced over 300k
heaviest 20 products sold last week but not over 30m long`}</SyntaxHighlighter>

                <p>Abodit NLP converts these English Language expressions into a <code>CompleteQuery</code> that references one of the <code>meta models</code> you
provided on startup together with a <code>TokenExpresson</code> for the sort order and a <code>TokenExpression</code> for the filter. You can
manipulate these expressions directly or you can use one of the provided TokenExpression Visitor classes to convert them
into SQL queries, or LINQ-to-object queries. Conversion to MongoDB and other document database queries is also possible.</p>
            </Article>
        );
    }
}