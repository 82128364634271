import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export class Grammar extends Component {
  static displayName = Grammar.name;

  render () {
    return (
      <Article title="Grammar" aside="Features">

<Helmet>
    <meta name="description" content="Defining a grammar for natural language understanding" />
    <title>Defining a grammar for natural language understanding</title>
</Helmet>

<p>In addition to defining sentences that you want to recognize, you can also define production grammar rules just like you would if you were building a
parser for that language.  The key difference between Abodit Production Rules and a typical grammar is that there are no restrictions on the grammar you
define for Abodit NLP.  It can be left recursive, right recursive or just plain ambiguous.</p>
<p>A production rule looks just like a sentence rule (and can use Optional and Permute parameters) but instead of returning an ActionResult it returns another Token. Production
rules are also placed in classes and those classed should be marked with the MEF export attribute <strong>[Export(typeof(INLPRule))]</strong> and the marker interface INLPRule.  Production
rules must be marked with the [ProductionRule] attribute.</p>
<p>For example, this rule handles phrases like <strong>5:30AM to 6PM</strong> and converts them to a TimeRange. </p>
<SyntaxHighlighter style={docco} language="csharp">{`[ProductionRule]
public TimeRange ProductionRule(Time a, Preposition.to to, Time b)
{
    return new TimeRange(a, b);
}`}</SyntaxHighlighter>

<p>The <em>[ProductionRule]</em> attribute also takes two optional parameters: one specifying the associativity of the rule, 
the other specifying the priority of the rule compared to other rules <em>defined in the same class</em>.  By defining 
priorities within a class you can express what order the rules should be applied, for example 3 + 4 * 5 should become 
23 and not 35:</p>

<SyntaxHighlighter style={docco} language="csharp">{`[Export(typeof(INLPRule))]
public class ExpressionOperators : INLPRule
{
    [ProductionRule(Attributes.Associativity.Left, priority: 1000)]
    public double Add(double a, Conjunction.plus plus, double b)
    {
        return a + b;
    }

    [ProductionRule(Attributes.Associativity.Left, priority: 2000)]
    public double Multiply(double a, Conjunction.asterisk times, double b)
    {
        return a * b;
    }
}`}</SyntaxHighlighter>

<p>Extending this class to handle subtraction and division and you have a simple expression parser.  To add support for 
parentheses, create another class like this:</p>
<SyntaxHighlighter style={docco} language="csharp">{`[Export(typeof(INLPRule))]
public class ExpressionParentheses : INLPRule
{
    [ProductionRule(Attributes.Associativity.None, priority: 1)]
    public double Parentheses(Punctuation.lparen lparen, double a, Punctuation.rparen rightParen)
    {
        return a;
    }

}`}</SyntaxHighlighter>

<p>Production rules allow you to build up sentences fragment by fragment and to create reuseable fragments 
that you can use in the sentences you define to be recognized.</p>
<p>You can also place the methods for combining tokens in the class that they produce either as a static 
factory method or as a constructor marked with the <code>[ProductionRule]</code> attribute.</p>
<p>Production rules can also verify their component parts and return null if production is not valid. 
For example, a blood pressure production rule might check that both values
are greater than zero.</p>

<SyntaxHighlighter style={docco} language="csharp">{`public class TokenBloodPressure
{
    public int Systolic { get; private set; }
    public int Diastolic { get; private set; } 

    [ProductionRule]
    public TokenBloodPressure BloodPressure (int systolic, Conjunction.slash slash, int diastolic)
    {
        if (systolic > 0 && systolic < 300 && diastolic > 0 && diastolic < 300)
            return new TokenBloodPressure { Systolic = systolic, Diastolic = diastolic };
        else
            return null;
    }

    public override string ToString()
    {
        return string.Format("{0}/{1}";, this.Systolic, this.Diastolic);
    }
}`}</SyntaxHighlighter>
</Article>
    );
  }
}
