import React, { Component } from 'react';
import { Article } from '../components/Article';
import { Link } from 'react-router-dom';

export class Sales extends Component {
    static displayName = Sales.name;

    render() {
        return (
            <Article title="Sales" aside="Applications">
                <p>The Abodit Natural Language Engine can integrate with your existing databases and CRM systems.
  You can query using plain english to find products, customers or sales data.</p>

                <p>For example, you could query to find customers to
     call by entering "<i>find customers last called over a month ago with sales last year over $2m</i>" and AboditNLP
     can translate this into a database query, interpreting <code>over a month ago</code>, <code>last year</code> and
     <code>$2m</code> as <Link to="/features/strongly-typed">strongly-typed</Link> objects or as a complete
     <code>MetaQuery</code> that you can use to generate a <Link to="/programming/meta-models">SQL query expression</Link>.</p>

                <p>For queries against a database of products AboditNLP understands units or measure and currency. It also understands
  computed fields, like <code>area = width x height</code>.

  When you build a <Link to="/programming/meta-models">meta model</Link> you
  specify the units and 'sense' for each database field. So it's not just a <code>double width</code>, it's
  a <code>horizontal distance measured in centimeters</code>. When you specify the true meaning of the fields
  in your database, AboditNLP can convert a query like <code>"over 5 inches wide"</code> into a query against
  the <code>width</code> field and it will do all the necessary
  conversions from inches to centimeters.

  You can see this in action in <Link to="/demo/database-query">this demonstration</Link>.
</p>

            </Article>
        );
    }
}