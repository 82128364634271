import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from "react-helmet"
import { MyInput } from '../components/myinput';
import { UIComponent } from '../components/UIComponent';
import { LinkPosting } from '../components/LinkPosting';
import { Row, Nav} from 'reactstrap';
import { ScrollToTopOnMount } from '../components/Scrolltotop';
import * as React from 'react';
import { IOverallState } from '../reducers';
import { tableLoading, updateExpression, updateInput, updatePlaceholder, updateTable } from '../actions/demo';

export const DateTime = () => {
    const demo = useSelector((state: IOverallState) => state.demo);
    const { input, placeholder, expression, table } = demo;
    const dispatch = useDispatch();

    //useEffect(() => {
    //    dispatch(updatePlaceholder('last thursday afternoon'));
    //    dispatch(updateTable({ id: -1, name: "p", properties: {}, children: [{ id: -2, name: "plain", text: "Enter a temporal expression or pick one from the right", properties: {}, children: [] }] }));
    //}, []);

    const sendMessage = (message: string) => {
        //console.log(message);

        dispatch(tableLoading());

        var request = {
            input: message,
            gridController: {
                skip: 0,
                take: 25,
                total: 0
            }
        }

        fetch('/api/demo/temporal', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        })
            .then(response => response.json())
            .then(data => {
                //console.log(data);

                dispatch(updateTable(data.table));
                dispatch(updatePlaceholder(''));
                dispatch(updateExpression(data.expressionText));
            });
    };

    const gsm = (e: any) => {
    };

    const linkClick = (value: string) => {
        dispatch(updateInput(value));
        sendMessage(value);
    };

    return (
        <Row>

            <Helmet>
                <meta name="description" content="Parsing date time expressions in dotnet" />
                <title>Demonstration of date time expression parsing</title>
            </Helmet>

            <ScrollToTopOnMount />
            <article className="post col-md-9">
                <div className="entry-content">
                    <h2 id="heading" className="entry-title">Database Query</h2>

                    <MyInput value={input} onChange={sendMessage} placeholder={placeholder} />

                    <p><code>{expression}</code></p>

                    <div className="row">
                        <h3>Table of results</h3>
                    </div>
                    <div className="chatBox">
                        <UIComponent obj={table} gsm={gsm} />
                    </div>

                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </article>

            <aside id="sidebar" className="widget col-md-3">
                <h3>Sample date time expressions to try</h3>
                <Nav vertical>
                    <LinkPosting linkClick={linkClick} value="last thursday afternoon">Simple (finite)</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="in the afternoon or evening">Time of day (infinite)</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="last year on a friday in May">Finite intersection</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="on a friday in May">Infinite intersection</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="thursday or friday">Union</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="1984, 1985 or 1986">Years</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="80s or 90s">Decades</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="next monday">Relative future</LinkPosting>
                </Nav>
            </aside>
        </Row>
    );
}