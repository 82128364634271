import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import { Link } from 'react-router-dom';

export class NLG extends Component {
  static displayName = NLG.name;

  render () {
    return (
      <Article title="Natural Language Generation" aside="Features">

<Helmet>
    <meta name="description" content="Natural language generation is the dual of natural language understanding." />
    <title>Natural language generation</title>
</Helmet>

<p>Closely related to Natural Language Parsing is Natural Language Generation.  Although this library doesn&#39;t specifically deal with Natural Language Generation
it includes concepts such as the <Link to="/features/word-network">word-network</Link> that make it easier for you to generate natural responses.</p>
<p>The library also prescribes a way to construct Question/Answer style scripts in which the response a user enters is interpreted in relation to any recent
questions the system has asked and/or any messages the system has sent.  For example the user can type <em>&#39;It was a junk call&#39;</em> and the system can deduce that <em>&#39;It&#39;</em>
refers to the phone call that it just alerted the user about and not the song that it just started playing. </p>
</Article>
    );
  }
}
