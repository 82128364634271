import React, { Component } from 'react';
import { Chat } from './ChatComponent';

export class Demo extends Component {
  static displayName = Demo.name;

    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
            <h1>Chat</h1>
            <Chat />
            </div>
        );
    }   
}
