import React, { Component } from 'react';
import { Button } from 'reactstrap';

export class LinkPosting extends Component {

    handleClick = () => {
      this.props.linkClick(this.props.value);
    }
  
    render() {
      return (
        <Button outline color="primary" size="sm" onClick={this.handleClick}>
          {this.props.children}
        </Button>
      );
    }
  }