import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom';
import { Article } from '../components/Article'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export class Sentences extends Component {
  static displayName = Sentences.name;

  render () {
    return (
      <Article title="Sentences" aside="Features">

<Helmet>
    <meta name="description" content="Sentences in NLP are recognized and cause the execution of C# methods" />
    <title>Defining sentences in code</title>
</Helmet>

<p>Sentences are the level at which the engine recognizes user input.  A sentence rule is defined using a C# method.
It takes as parameters the parsed tokens from the input (words, values, database objects, ...) and returns a single
value <code>None</code> to indicate that it was successful in responding to the sentence.</p>
<p>You can think of these sentence rules as being a bit like action methods in ASP.NET MVC 3+. When a request comes in
the engine picks the appropriate controller class, instantiates it (using dependency injection), and then executes
the action method. The &#39;routing engine&#39; that the NLP engine uses to map user input onto these methods is however much more 
complicated than an MVC route because it converts plain english
text into tokens and then matches those tokens to rules whilst allowing permutations and optional parameters. Also, unlike
ASP.NET MVC a rule can run for an indeterminate period of time, asynchronously responding to the user, or it can return a
value <code>Continue</code> to indicate that it could not work with the tokens passed to it, and that allows another lower priority
rule to run.</p>

<p>Here&#39;s a sample sentence rule:</p>

<SyntaxHighlighter style={docco} language="c#">{`  public NLPActionResult ExerciseForADistanceOrTime
       (I me,
        [Verb.Tense.Past]Verb.Type.Exercise verb, 
        [Permute][Optional]Distance distance,
        [Permute][Optional]TimePeriod time,
        [Permute][Optional]TemporalSetCollection when)
       {
        ...
         return NLPActionResult.None;
       }
`}
</SyntaxHighlighter>

<p>This one rule will fire for literally millions of possible user inputs including sentences like:</p>
<pre><code>I ran 2 miles
I walked for 20 minutes this afternoon
I swam 100 yards
I rode 27.8 miles
...</code></pre>
<p>Each parameter in the sentence can be any of:-</p>
<ul>
<li>An interface representing a subset of the common words defined as <Link to="/features/lexemes">lexemes</Link> (e.g. Verb.Type.Past)</li>
<li>A common .NET class like int, double, ...</li>
<li>One of the provided token classes like &#39;Distance&#39;, &#39;TimePeriod&#39;, ...</li>
<li>The special TemporalSet class (which merits separate discussion)</li>
<li>A custom token class that you write to recognize specific words of interest (e.g. company part numbers, invoice numbers, ...)</li>
<li>A custom token class that you write that queries a database (e.g. NetflixMovie) during the parsing operation</li>
</ul>
<p>Rules exist in classes that are instantiated prior to rule execution. Any context a rule needs can be provided through these 
controller classes (e.g. a <code>User</code> object representing the current user, a <code>History</code> object representing the conversation history, ...)
This context is injected either by passing values to the <code>Execute</code> method or 
through <Link to="/programming/dependency-injection">a dependency injection framework</Link> like Autofac.</p>
<p>The whole mechanism by which AboditNLP responds to the user is, in fact, just another injected object which means
the engine itself is totally decoupled from how you choose to implement conversations and conversational memory, from whether you choose
synchronous (e.g. email) or asynchronous (e.g. chat) communication, and whether you format the responses as plain text, HTML, markdown or
more complex multi-valued responses containing both text and webpage panel updates like you will see in the demo later.</p>
</Article>
    );
  }
}
