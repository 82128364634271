import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom';
import { Article } from '../components/Article'

export class StronglyTyped extends Component {
  static displayName = StronglyTyped.name;

  render () {
    return (
      <Article title="Strongly-typed" aside="Features">

<Helmet>
    <meta name="description" content="Abodit NLP is strongly-typed, when it parses something it delivers a real dot net object for it. " />
    <title>Strong-typing for NLP</title>
</Helmet>

<p>For the most part, historical efforts at <a href="http://en.wikipedia.org/wiki/Natural_language_processing">Natural Language Processing</a> have
been concerned with statistical &#39;understanding&#39; of words and senetences. These approaches include tasks like tagging the parts of speech
in a sentence, finding named entities, classifying texts into topics, detecting &#39;sentiment&#39; and other &#39;approximate&#39; tasks.
</p>
<p>In these applications there might not be a &#39;right&#39; answer, 
instead the output is often a &#39;bag of words&#39; and a set of probabilities. Machine learning
may be used to further classify the input.</p>

<p>The Abodit Natural Language engine is not like that.  It was built from the ground-up for command, control and query applications where the computer needs to 
understand precisely what was said and then needs to act on that input to do something or to return the result of some query.</p>
<blockquote>
<p>Abodit NLP converts an input of <em>&#39;nineteen&#39;</em> into an <code>TokenInt</code> value, 
a time like <em>&#39;4pm&#39;</em> into a <code>Time</code> object, 
a distance like <em>&#39;400m&#39;</em> into a <code>Distance</code> object, and a complex date and time utterance like 
<em>&#39;last year in May on a friday between 4pm and 9pm&#39;</em> into a <code>TemporalSet</code> that can be used
to query a database or to update a calendar or task list.</p>
</blockquote>

<p>The unit of work in Abodit isn&#39;t just a single word, it&#39;s a token, possibly spanning multiple words, that represents a real-world object (modelled as a .NET class) 
or a <Link to="/features/lexemes">lexeme (a unit of meaning)</Link>. The objects it delivers can be the built-in types it knows how to parse (cardinal values, ordinal values,
numeric expressions, times, dates, complex temporal expessions, distances, weights, ...) or types loaded from database records (customers, products, songs, artists, ...).</p>
<p>This ability to parse meanings rather than just words means that a movie title like <em>&#39;2001 A space odyssey&#39;</em> becomes a single instance of a .NET <code>Movie</code> class
when passed to your sentence method or rule. This greatly simplifies writing sentence rules: often they are just a couple of statements.</p>
      </Article>
    );
  }
}
