import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';

export class TemporalSets extends Component {
  static displayName = TemporalSets.name;

  render () {
    return (
     <Article title="Temporal Sets" aside="Programming">

<Helmet>
  <title>Temporal Sets - Abodit NLP</title>
  <meta name="description" content="Abodit NLP understands temporal expressions through temporal sets" />
</Helmet>

<p>By including a <code>TemporalSet</code> parameter as one of the parameters in a rule method you can ask AboditNLP
to recognize a wide variety of temporal expressions. The result is however still ambiguous at this stage
as <code>Monday</code>, for example, could mean last monday, next monday or every monday.</p>
<p>The context to decide which of these meanings to ascribe to the temporal set comes from your rule.
A query for example typically involves the past so you would take the TemporalSet and
call <code>EnumerateBackward</code> passing it the current time. A request to put a meeting on a calendar implies
a future time so you would use <code>EnumerateForward</code>.</p>
<pre><code>var backward = ts.EnumerateBackward(TimeProvider.Current.CalendarNow);</code></pre>
<p>[Note the use of <code>TimeProvider</code> not <code>DateTime.Now</code>. TimeProvider allows for proper testing as you can
substitute your own TimeProvider and set the time on it manually while testing.]</p>
<p>TemporalSets are either <code>IFinite</code> or <code>IInfinite</code>. The former can be enumerated without specifying a direction.</p>
<p>A <code>TemporalSet</code> is similar to an <code>Expression</code> tree in .NET. You can combine Temporal sets using <code>Union</code>, 
or intersect them using <code>Intersect</code>. If you intersect a infinite temporal set with a finite one you get a finite
one. Infinite temporal sets can also be half-infinite (e.g. before a given point in time) or infinite in
both directions. This allows some intersections of infinite temporal sets to become finite (e.g. before X and after Y).</p>
<p>During parsing the engine creates a <code>TemporalExpression</code> tree and then flattens any portions of it that it
can simplify. Thus it can convert <code>one week after two days before three days before today</code> into a single
calendar range.</p>
<p>In addition to the obvious times and dates, here are some examples of temporal expressions that the 
engine can handle:</p>
<pre><code>
{`  
    1970s
    '70s
    seventies
    the 1970s
    the '70s
    the seventies
    the first Sunday in may 2013
    the Tuesday before last
    Tuesday before last
    the third Wednesday of this month
    the first Sunday of this month
    the first week in May 2013
    three days before 3/1/2013
    three days after 3/1/2013
    three days before the first week in May 2013
    October of 1963
    this weekend
    next weekend
    last weekend
    this year
    last year
    next year
    today
    tomorrow
    the day after tomorrow
    this saturday morning
    Wednesday 2nd September 2015
    Wednesday, 2nd September 2015
    the 2nd Wednesday in September 2015
    last friday
    last friday night
    next september
    next October
    last May
    this July
`}
</code></pre>

<p>It can also handle relative expressions like:</p>
<pre><code>{`    5 minutes ago
    5 hours from now
    in 5 secs
    in 90 secs
    90 seconds from now
    90 seconds ago
    in three days
    in three days time
`}</code></pre>
<p>And if you find a temporal expression it can&#39;t handle, send me an email and I&#39;ll get it added.</p>
      </Article>
    );
  }
}
