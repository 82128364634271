import * as React from 'react';
import { useEffect, useState } from 'react';

export interface IMyInputProps {
    value: string,
    placeholder: string,
    onChange: (input: string) => void,
    /** other props */
    [x: string]: any
}

export const MyInput = (props: IMyInputProps) => {

    let { value, onChange, placeholder, ...otherprops } = props;

    // initial value passed in
    const [v, setValue] = useState(value);

    useEffect(() => setValue(value), [value]);

    const inputChanged = (e: any) => { setValue(e.target.value); }

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            onChange(v);
            //setValue('');
            e.preventDefault();
        }
    }

    const buttonClick = (e: any) => {
        // call the onChange event passed in
        console.log('send', v);
        onChange(v);
        e.preventDefault();
    }

    return (
        <div className="row">
            <input value={v}
                placeholder={placeholder}
                onChange={inputChanged}
                onKeyDown={keyPress}
                type="text"
                maxLength={80}
                className="chatInput col-md-8"
                {...otherprops}
            />
            <button onClick={buttonClick} color="danger" className="chatButton col-md1">Send</button>
        </div>
    );
}

export default MyInput;