import React, { Component } from 'react';
import { Helmet } from "react-helmet"
//import { Link } from 'react-router-dom';
import { Article } from '../components/Article'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export class WordNetwork extends Component {
  static displayName = WordNetwork.name;

  render () {
    return (
      <Article title="Word network" aside="Features">

<Helmet>
    <meta name="description" content="A graph of words supports classes of meanings and conjugations." />
    <title>Word network</title>
</Helmet>

<p>When the engine recognizes a common word and hands back a Lexeme, that lexeme exists as a node in a network of related lexemes.  Relationships between
lexemes can include different conjugations of a verb (e.g. x is a past tense of y), opposites (e.g. x is opposite of y), 
adjective forms (comparative of, superlative of, ...),
connections between verb, noun, adjective and adverb forms of a root (e.g. quickly is adverb form of adjective quick).  </p>
<p>This is one reason why it&#39;s important for each meaning of a word to be represented as a separate Lexeme - each meaning exists in a separate network of related lexemes.</p>
<h2 id="conjugation">Conjugation</h2>
<p>You can use the word network provided to conjugate verbs or to find the noun form of a verb.  For example, you create a rule that
expects the past tense of a verb &#39;I <em>printed</em> an invoice&#39; but when you reply back to the user
you want to reply &#39;Which invoice did you <em>print</em>?&#39;.  In code this might be:</p>

<SyntaxHighlighter style={docco} language="c#">{`    st.Say("Which invoice did you "; + verb.PresentPlural.Text);
`}</SyntaxHighlighter>

<p>As you can see Natural Language Generation is intimately related to Natural Language Parsing 
  and the word-network is an important element of both.</p>

      </Article>
    );
  }
}
