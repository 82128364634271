import React, { Component } from 'react';
import { Helmet } from "react-helmet"

import { Link } from 'react-router-dom';
import { Article } from '../components/Article'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export class Wordnet extends Component {
  static displayName = Wordnet.name;

  render () {
    return (
      <Article title="Wordnet" aside="Features">

<Helmet>
  <title>Using Wordnet in dotnet</title>
  <meta name="description" content="Wordnet integration for dotnet recognizes thousands of words, synonyms and definitions" />
</Helmet>

<p>An optional add-on module adds the complete WordNet database from Princeton university including synonyms, definitions and more.</p>
<p>This add-on provides you with strongly-typed interfaces that represent meanings for any given Wordnet synset, e.g.</p>
<SyntaxHighlighter style={docco} language="c#">{` /// <summary>
/// The class of all mammals (anything that has a
/// superclass synonymous with wn30:synset-mammal-noun-1)
/// </summary>
public interface Mammal1 : INoun { }

/// <summary>
/// The word mammal or synonyms 
/// (wn30:synset-mammal-noun-1)
/// </summary>
public interface mammal1 : INoun { }
`}</SyntaxHighlighter>

<p>You can use these interfaces in any <Link to="../programming/rules">rules</Link> you create to match either the specific word, or all members of a given class.  
In this case a rule asking for a <em>Mammal1</em> will match lion, tiger, dog, aardvark, ...</p>
<p>AboditNLP extends WordNet with many additional words and phrases (mostly modern or technical words that are
not present in the WordNet source).</p>
      </Article>
    );
  }
}
