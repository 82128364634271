import React, { Component } from 'react';
import { Helmet } from "react-helmet"
//import { Link } from 'react-router-dom';
import { Article } from '../components/Article'

export class Transport extends Component {
  static displayName = Transport.name;

  render () {
    return (
      <Article title="Transport" aside="Features">

<Helmet>
    <meta name="description" content="Whatever transport you use, Abodit NLP can work with it: Email, Messenger, Slack, XMPP, ..." />
    <title>Transports for NLP messages</title>
</Helmet>

<p>The Abodit Natural Language Engine works with any text-based communication transport including SMS, Chat, 
Email, Slack, or web-based chat.  You can also connect it to a simple INPUT field on an HTML page.</p>

<h3 id="synchronous-or-asynchronous-communication-style">Synchronous or Asynchronous Communication Style</h3>
<p>Depending on the transport you may wish to vary how responses are delivered. Email for example would require
all responses to a given command to be collected as sent as a single message. Chat however might benefit 
from a more asynchronous approach whereby each response is sent as it is received. An asynchronous chat system 
can also initiate conversations instead of just responding to user input.</p>

<h3 id="custom-collectors">Custom Collectors</h3>
<p>Abodit NLP has been designed to be completely independent from the transport chosen: an 
interface <code>IListener</code> is provided that you can use to create a connector and inject it into your rules
but you can also design your own from scratch.</p>
<p>A synchronous connector, like you might use for email, collects the whole response and then emails it back.
An SMS collector might also function in a synchronous manner collecting muliple lines and then packaging
them up into 160 character chunks to minimize sending costs. In both of these cases the <code>IListener</code> that
you inject exists for just the lifetime of the execution of the sentence.</p>
<p>An asynchronous connector on the other hand, like a chat collector, can respond over a much longer period
of time. The incoming requests typically fire off a <code>Task</code> which collects and reports back to the user over
potentially several minutes or hours. For a very long running task you may wish to remember the task in a
a history object so that the user can cancel it later using a &#39;stop&#39; command.</p>
<p>An asynchronous connector can also initiate conversations with a user without requiring them 
to start each conversation with an input.</p>

<table class="table table-striped table-bordered">
<thead class="thead-dark">
    <tr><td><b>Transport</b></td><td><b>Async</b></td><td><b>Text</b></td><td><b>Images</b></td><td><b>Emoticons</b></td></tr>
</thead>
<tbody>
<tr><td>Email </td><td>-</td>   <td>Yes</td>  <td>Yes</td>    <td>-</td></tr>
<tr><td>SMS   </td><td>Yes</td> <td>Yes</td>  <td>-</td>      <td>-</td></tr>
<tr><td>Twilio   </td><td>Yes</td> <td>Yes</td>  <td>-</td>      <td>-</td></tr>
<tr><td>XMPP  </td><td>Yes</td> <td>Yes</td>  <td>-</td>      <td>-</td></tr>
<tr><td>Slack </td><td>Yes</td> <td>Yes</td>  <td>Yes</td>    <td>Yes</td></tr>
<tr><td>Telegram </td><td>Yes</td> <td>Yes</td>  <td>Yes</td>    <td>Yes</td></tr>
<tr><td>WebApi </td><td>No</td> <td>Yes</td>  <td>Yes</td>    <td>Yes</td></tr>
<tr><td>SignalR </td><td>Yes</td> <td>Yes</td>  <td>Yes</td>    <td>Yes</td></tr>
</tbody>
</table>
      </Article>
    );
  }
}
