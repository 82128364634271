import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Link } from 'react-router-dom';

export class Rules extends Component {
  static displayName = Rules.name;

  render () {
    return (
      <Article title="Rules" aside="Programming">

<Helmet>
  <title>Rules - Abodit NLP</title>
  <meta name="description" content="Abodit NLP uses methods you write for rules" />
</Helmet>

<p>Rules define the sentences that the engine will recognize. Rules are defined in classes that inherit 
from the <code>INLPRule</code> interface. These classes take part
in <Link to="dependency-injection">dependency injection</Link> - their constructor can be satisfied
by pulling the required parameters from an existing dependency injection framework (Autofac is
preferred and an Autofac to AboditNLP implementation is provided), and/or by specifying additional parameters in the
call to the <code>Execute</code> or <code>Parse</code> methods.
</p>
<h2 id="rule-class-constructor">Rule Class Constructor</h2>
<p>
For example, this class constructor expects services for sending email, for getting the current user and for updating
information about a user. It also expects an <code>IListener</code> which might be an object for 
responding to the user in a chat-style interface and for maintaining a chat history and the ability to respond
in the context of previous inputs or results. The <code>IListener</code> approach is entirely optional, you may
implement any other approach for returning the response to NLP input. If you use the <code>Parse</code> method
instead of the <code>Execute</code> you don't need it at all.
</p>
<SyntaxHighlighter style={docco} language="csharp">{`public DemoSentences(
    IListener st,
    IUserService userService, 
    IUser user, 
    IEmailService emailService)
{`}</SyntaxHighlighter>

<h2 id="rule-action-methods">Rule Action Methods</h2>
<p>A sentence rule is defined using a C# method that behaves much like an ASP.NET MVC action method. Here&#39;s a 
sample sentence rule:</p>
<SyntaxHighlighter style={docco} language="csharp">{`public NLPActionResult ExerciseForADistanceOrTime
    (I me,
    [PastTense]Verb.Type.Exercise verb,
    [Permute][Optional]Distance distance,
    [Permute][Optional]TimePeriod time,
    [Permute][Optional]TemporalSetCollection when)
{
    ...
    return NLPActionResult.None;
}`}</SyntaxHighlighter>

<p>This one rule will fire for literally millions of possible user inputs including sentences like:</p>
<SyntaxHighlighter style={docco} language="plaintext">{`I ran 2 miles
 I walked for 20 minutes this afternoon
 I swam 100 yards
 I rode 27.8 miles
 ...`}</SyntaxHighlighter>

<p>Each parameter in the sentence can be any of:-</p>
<ul>
<li>An interface representing a single meaning, a '<Link to="/features/lexemes">lexemes</Link>' (e.g. <code>Verb.send</code>)</li>
<li>An interface representing a group of meanings, e.g. <code>Noun.Mammal</code> will match all mammals</li>
<li>Either of the above with additional <Link to="attributes">attributes</Link> to restrict recognition to a given verb tense, noun or adjective form, e.g. <code>Verb.Tense.Past</code>, <code>Noun.Type.Plural</code></li>
<li>A common .NET class like int, double, ... (although it&#39;s generally better to use TokenInt, TokenNumericExpression or Cardinal or Ordinal to be more specific about what
type of value you want).</li>
<li>One of the provided token classes like &#39;Distance&#39;, &#39;TimePeriod&#39;, ...</li>
<li>The special <Link to="temporal-sets">TemporalSetCollection</Link> class (which merits <Link to ="temporal-sets">separate discussion</Link>)</li>
<li>A custom token class that you write to recognize specific words of interest (e.g. company part numbers, invoice numbers, ...)</li>
<li>A custom token class that you write that queries a database (e.g. NetflixMovie) during the parsing operation</li>
</ul>

<h2 id="productionrule-methods">Execute Rule Methods</h2>
<p>Rules that return an NLP Action result can be called using the <code>Execute</code> method. All other rules are
 production rules that may in turn cascade to define a complete grammar.
</p>
<p>If you return <code>NLPActionResult.None</code> excution ends after your rule has run, but if you 
return <code>NLPActionResult.Continue</code> other methods that can also match the parameters are executed. This allows
two (or more) methods to take a peek at the tokens parsed and to decide whether or not they can handle
the request. A rule that handles &#39;It was&#39; followed by some phrase might look back in the history, see that there
is no pending phone call and quit without doing anything. A lower priority rule that also recognizes &#39;It was ...&#39;
now gets a chance to execute. </p>
<p>You can think of the rules as behaving like action methods and the classes they live in as controllers in
ASP.NET MVC; the main differences being that routing is handled by matching sentences to parameters and that
one NLP rule can handle millions of possible orderings of parameters.</p>

<h2 id="productionrule-methods">Production Rule Methods</h2>
<p>
 You can also define rules marked with a <code>[ProductionRule]</code> attribute that convert any sequence of
 parsed objects into another object. See <Link to="production-rules">production rules</Link>. These rules
 can also be used with the <code>Parse</code> method.
</p>

</Article>
     );
  }
}
