import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Aside } from '../components/Aside'
import { ScrollToTopOnMount } from './Scrolltotop';

export class Article extends Component {
  static displayName = Article.name;

  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      aside: props.aside
    };
  }

  render() {
    return (
      <Row>
        <ScrollToTopOnMount />
        <article className="post col-md-9">
          <div className="entry-content">
            <h2 id="heading" className="entry-title">{this.state.title}</h2>
            {this.props.children}
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </article>
        <Aside title={this.state.aside}/>
      </Row>  );
  }
}
