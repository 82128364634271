import { useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import * as React from 'react';

export const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);

    //this.toggleNavbar = this.toggleNavbar.bind(this);

    const toggleNavbar = () => { setCollapsed(!collapsed); }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">Abodit NLP</NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                            </NavItem>
                            <UncontrolledDropdown nav>
                                <DropdownToggle nav caret className="text-dark" >Applications</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/applications/sales">Sales</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/applications/customer-service">Customer Service</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/applications/inventory">Inventory</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/applications/home-automation">Home Automation</NavLink></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown nav>
                                <DropdownToggle nav caret className="text-dark" >Features</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/conversational">Conversational</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/lexemes">Lexemes</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/grammar">Grammar</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/sentences">Sentences</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/word-network">Word network</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/wordnet">Wordnet</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/strongly-typed">Strongly-typed</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/metamodel">Metamodel</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/transport">Transport</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/features/nlg">NLG</NavLink></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown nav>
                                <DropdownToggle nav caret className="text-dark" >Programming</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/overview">Overview</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/dependency-injection">Dependency Injection</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/startup">Startup</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/rules">Rules</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/production-rules">Production Rules</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/lexemes">Lexemes</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/tokens">Tokens</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/temporalsets">Temporal Sets</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/attributes">Attributes</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/programming/meta-models">Meta Models</NavLink></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown nav>
                                <DropdownToggle nav caret className="text-dark" >Demo</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/demo/database-query">Database query</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/demo/datetime-input">Datetime input</NavLink></DropdownItem>
                                    <DropdownItem divider />
                                    {/* <DropdownItem><NavLink tag={Link} className="text-dark" to="/demo/math-and-units">Math and Units</NavLink></DropdownItem>
                                        <DropdownItem><NavLink tag={Link} className="text-dark" to="/demo/wordnet">Wordnet</NavLink></DropdownItem>
                                        <DropdownItem divider /> */}
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/demo/chat">Chat</NavLink></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/download">Download</NavLink>
                            </NavItem>

                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default NavMenu;