import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
//import { Link } from 'react-router-dom';

export class ProductionRules extends Component {
    static displayName = ProductionRules.name;

    render() {
        return (
            <Article title="Production Rules" aside="Programming">

                <Helmet>
                    <title>Production Rules - Abodit NLP</title>
                    <meta name="description" content="Production rules define a grammar for clauses you want to recognize" />
                </Helmet>

                <p>Production Rules can be used to define a grammar. A production rule is a method that
                takes a sequence of tokens and reduces them to a new single token. Production rules need to
have a <code>[ProductionRule]</code> attribute placed on them to distinguish them from normal methods in an assembly.
They also need to exist in a class using the <code>INlpRule</code> marker interface.</p>
                <p>You can also set a priority and an associativity on a production rule.  For example, to
                define expressions you would use an associativity of Left and a priority for * that is higher than that for +.
Methods in the same class are compared by priority.  Methods in different classes are not compared by priority.</p>

                <p>You can also set a `probability` (range 0.0-1.0) on a production rule and this will adjust the relative score for
                two or more possible interpretations of a sentence. This capability is made more powerful by the ability
                to return a `RuleResult&lt;T&gt;` from a production rule and adjust the probabilities in a data dependent fashion.
</p>

                <p>For example the following code defines a complete expression parser for numeric expressions involving double values where the expression is
calculated at parse time.  You could define a similar expression parser that produced actual .NET Expression values.</p>
                <SyntaxHighlighter style={docco} language="csharp">{`
// Note how the production rule for parentheses is declared in a separate class
// because they do not have a priority relative to expression operators
// Rules that interact using precedence and priority must be placed in the same class.

public class ExpressionParentheses : INLPRule
{
    [ProductionRule(Associativity.None, priority: 1)]
    public double Parentheses(Punctuation.lparen lparen,
                                double a,
                                Punctuation.rparen rightParen)
    {
        return a;
    }
}

public class ExpressionOperators : INLPRule
{
    [ProductionRule(Associativity.Left, priority: 1000)]
    public double Add(double a, plus plus, double b)
    {
        return a + b;
    }

    [ProductionRule(Associativity.Left, priority:1000)]
    public double Subtract(double a, dash dash, double b)
    {
        return a - b;
    }

    [ProductionRule(Associativity.Left, priority: 2000)]
    public double Multiply(double a, asterisk times, double b)
    {
        return a * b;
    }

    [ProductionRule(Associativity.Left, priority: 2000)]
    public double Divide(double a, slash divide, double b)
    {
        return a / b;
    }

    [ProductionRule(Associativity.Left, priority: 3000)]
    public double Multiply(double a, caret times, double b)
    {
        return Math.Pow(a, b);
    }
}`}</SyntaxHighlighter>
                <p>When defining production rules be careful to avoid a cycle where one rule converts an A to a B and another
                rule converts it back. If all your production rules are reductions (i.e. more than one parameter) you can
avoid this problem.</p>

                <p>Production rules are also useful for remapping a set of phrases to a single token, e.g. create a token class
called <code>ExpressesANeed</code> and then create production rules that map &#39;I need&#39;, &#39;I want&#39;, &#39;Buy&#39;, ... onto that class.
Use this technique sparingly as a better option exists for single words where you can define a common interface
and recognize all instances of any Lexemes bearing that interface.
</p>
            </Article>
        );
    }
}