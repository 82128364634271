import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Article } from '../components/Article';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export class DependencyInjection extends Component {
  static displayName = DependencyInjection.name;

  render () {
    return (
      <Article title="Dependency Injection" aside="Programming">

<Helmet>
  <title>Dependency Injection - Abodit NLP</title>
  <meta name="description" content="Abodit NLP can resolve dependencies from common DI containers like Autofac or you can inject dependencies manually." />
</Helmet>

<p>Registration of the rules, production rules, Token factories and Assemblies used by AboditNLP 
   is accomplished using a dependency injection container. Currently there is an adapter 
   for Autofac, others can easily be added<sup>1</sup>.
 </p>

<p>Dependency injection is also used to inject objects into your own rules or token generating classes,
   you can do this using the same dependency injection as AboditNLP uses or you can simply inject
   additional dependencies on a per-request basis directly into the calls 
   to <code>Execute</code> or <code>Parse</code>.
</p>

<p>An interface <code>INLPPart</code> is provided to make registration easy, it wraps all of the other NLP
 interfaces that are injectable
 including <code>ITokenFactory</code>, <code>ILexemeStore</code> and <code>INLPRule</code>. The Autofac
 nuget also includes an extension method to register all of the parts in a given assembly.
 </p>

<p>Here's the setup code for Autofac:</p>
<SyntaxHighlighter style={docco} language="csharp">
{`// A LexemeStore is a place to store words and their meanings
// You can inject additonal ILexemeStore objects on a per-request basis
builder.RegisterType<LexemeStore>().SingleInstance()
   .AsImplementedInterfaces();

// NLP should be registered as a singleton
builder.RegisterType<NLP>().SingleInstance()
   .AsImplementedInterfaces();

// Register all of the ITokenFactory, ILexemeStore and INLPRule
// classes in all relevant assemblies.
builder.RegisterAllNlpParts(typeof(NLP).Assembly);

// Do the same for each of your assemblies containing rules
builder.RegisterAllNlpParts(...)...
`}
</SyntaxHighlighter>
<p>Note that If you are using the optional Wordnet extension you need to register it too; here we do that using
one of the interfaces or classes from it: e.g. <code>a_cappella1</code> or <code>W00</code>.
</p>
<SyntaxHighlighter style={docco} language="csharp">
{`// Assembly containing Wordnet
builder.RegisterAllNlpParts(typeof(AboditNLP.W00).Assembly);
`}
</SyntaxHighlighter>

<p>
   <sup>1</sup> The Microsoft dotnet core container is, unfortunately, not supported as it does not have nested scopes which Abodit NLP needs.
</p>
      </Article>
     );
  }
}
