import React, { Component } from 'react';

export class MathAndUnits extends Component {
  static displayName = MathAndUnits.name;

  render () {
    return (
      <div>
        <h1>Math and Units of Measure</h1>
      </div>
    );
  }
}
