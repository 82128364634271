import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Download } from './components/Download';

import { Sales } from './applications/Sales';
import { CustomerService } from './applications/CustomerService';
import { Inventory } from './applications/Inventory';
import { HomeAutomation } from './applications/HomeAutomation';

import { DatabaseQuery } from './demo/DatabaseQuery';
import { DateTime } from './demo/DateTime';
import { MathAndUnits } from './demo/MathAndUnits';
import { Demo } from './demo/Demo';
import { WordnetDemo } from './demo/WordnetDemo';

import { Lexemes } from './features/lexemes';
import { Conversational } from './features/conversational';
import { Grammar } from './features/grammar';
import { Sentences } from './features/sentences';
import { WordNetwork } from './features/wordnetwork';
import { Wordnet } from './features/wordnet';
import { StronglyTyped } from './features/stronglytyped';
import { Metamodel } from './features/metamodel';
import { NLG } from './features/nlg';
import { Transport } from './features/transport';

import { Overview } from './programming/overview';
import { Attributes } from './programming/attributes';
import { DependencyInjection } from './programming/dependencyinjection';
import { Features } from './programming/features';
import { Lexemes2 } from './programming/lexemes2';
import { Metamodels } from './programming/metamodels';
import { ProductionRules } from './programming/productionrules';
import { Rules } from './programming/rules';
import { Startup } from './programming/startup';
import { TemporalSets } from './programming/temporalsets';
import { Tokens } from './programming/tokens';

import { withAITracking } from '@microsoft/applicationinsights-react-js';

import ai from './appInsights';

import './custom.css'

const appInsights = ai;
const reactPlugin = ai.reactPlugin;

class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content="A dotnet library for Natural Language Understanding" />
                    <title>AboditNLP Natural Language Engine for dotnet</title>
                    <link rel="canonical" href="https://nlp.abodit.com" />
                </Helmet>

                <Route exact path='/' component={Home} />
                <Route path='/download' component={Download} />

                <Route path='/applications/sales' component={Sales} />
                <Route path='/applications/customer-service' component={CustomerService} />
                <Route path='/applications/inventory' component={Inventory} />
                <Route path='/applications/home-automation' component={HomeAutomation} />

                <Route path='/demo/database-query' render={(props) => {
                    return <DatabaseQuery {...props} appInsights={appInsights} />;
                }} />
                <Route path='/demo/datetime-input' render={(props) => <DateTime {...props} appInsights={appInsights} />} />

                <Route path='/demo/math-and-units' component={MathAndUnits} />
                <Route path='/demo/wordnet' component={WordnetDemo} />

                <Route path='/demo/chat' render={(props) => <Demo {...props} appInsights={appInsights} />} />

                <Route path='/features/conversational' component={Conversational} />
                <Route path='/features/grammar' component={Grammar} />
                <Route path='/features/lexemes' component={Lexemes} />
                <Route path='/features/metamodel' component={Metamodel} />
                <Route path='/features/nlg' component={NLG} />
                <Route path='/features/sentences' component={Sentences} />
                <Route path='/features/strongly-typed' component={StronglyTyped} />
                <Route path='/features/transport' component={Transport} />
                <Route path='/features/wordnet' component={Wordnet} />
                <Route path='/features/word-network' component={WordNetwork} />

                <Route path='/programming/overview' component={Overview} />
                <Route path='/programming/dependency-injection' component={DependencyInjection} />
                <Route path='/programming/production-rules' component={ProductionRules} />
                <Route path='/programming/startup' component={Startup} />
                <Route path='/programming/attributes' component={Attributes} />
                <Route path='/programming/lexemes' component={Lexemes2} />
                <Route path='/programming/meta-models' component={Metamodels} />
                <Route path='/programming/production-rules' component={ProductionRules} />
                <Route path='/programming/rules' component={Rules} />
                <Route path='/programming/temporalsets' component={TemporalSets} />
                <Route path='/programming/tokens' component={Tokens} />

            </Layout>
        );
    }
}

export default withAITracking(reactPlugin, App);