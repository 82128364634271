import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from "react-helmet"
import MyInput from '../components/myinput';
import { UIComponent } from '../components/UIComponent';
import { LinkPosting } from '../components/LinkPosting';
import { Row, Nav } from 'reactstrap';
import { ScrollToTopOnMount } from '../components/Scrolltotop';
import * as React from 'react';
import { IOverallState } from '../reducers';
import { tableLoading, updateExpression, updateInput, updatePlaceholder, updateTable } from '../actions/demo';
import { somethingIsLoading } from '../actions/loading';

export const DatabaseQuery = () => {
    const demo = useSelector((state: IOverallState) => state.demo);
    const { input, placeholder, expression, table } = demo;

    const dispatch = useDispatch();

    console.log('DatabaseQueryPage', demo);

    //useEffect(() => {
    //    dispatch(updatePlaceholder('3 products over 30mm wide'));
    //    dispatch(updateTable({ id: -1, name: "p", properties: {}, children: [{ id: -2, name: "plain", text: "Enter a query or pick one from the right", properties: {}, children: [] }] }));
    //}, []);

    const sendMessage = (message: string) => {

        dispatch(updatePlaceholder(''));  // remove example
        dispatch(tableLoading());
        dispatch(updateExpression(''));
        dispatch(somethingIsLoading(true));

        var request = {
            input: message,
            gridController: {
                skip: 0,
                take: 25,
                total: 0
            }
        }

        fetch('/api/demo/products', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        })
            .then(response => response.json())
            .then(data => {
                dispatch(updateTable(data.table));
                dispatch(updateExpression(data.expressionText));
                dispatch(updatePlaceholder(''));
            });
    }

    const linkClick = (value: string) => {
        dispatch(updateInput(value));
        sendMessage(value);
    };

    const gsm = () => { }

    return (
        <Row>
            <Helmet>
                <meta name="description" content="A demonstration of the Abodit Natural Language Engine for dotnet" />
                <title>Demonstration of the AboditNLP Natural Language Engine for dotnet</title>
            </Helmet>

            <ScrollToTopOnMount />
            <article className="post col-md-9">
                <div className="entry-content">
                    <h2 id="heading" className="entry-title">Database Query</h2>

                    <MyInput value={input} onChange={sendMessage} placeholder={placeholder} />

                    <p><code>{expression}</code></p>

                    <div className="row">
                        <h3>Table of results</h3>
                    </div>
                    <div className="chatBox">
                        <UIComponent obj={table} gsm={gsm} />
                    </div>

                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </article>

            <aside id="sidebar" className="widget col-md-3">
                <h3>Sample database queries to try</h3>
                <Nav vertical>
                    <LinkPosting linkClick={linkClick} value="3 products between 2 and 4 inches wide">Units of measure</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="invoices over £300">Currency conversion</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="10 highest profit products">Sorting</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="3 products over 3cm wide and over 40cm tall">Boolean logic</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="3 products with area over 40">Calculated fields</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="3 products sold last year">Datetime expressions</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="5 second hand products">Enum fields</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="5 open invoices">Boolean fields</LinkPosting>
                    <LinkPosting linkClick={linkClick} value="3 products over 3g">Inferred fields</LinkPosting>
                </Nav>
            </aside>
        </Row>
    );
}

export default DatabaseQuery;