import React, { Component } from 'react';
import { Article } from './Article'

export class Download extends Component {
    static displayName = Download.name;

    render() {
        return (
            <Article title="Download" aside="Licensing">

                <p>You can download the Abodit Natural Language Engine using <a href="https://www.nuget.org/packages/AboditNLP">Nuget</a>.
  This will install just the core module.
You can add other Nuget packages under the Abodit name including a core WordNet package,
an extended WordNet package and an Autofac integration package.</p>

                <p> The WordNet packages include over one hundred thousand words together with definitions and a complete network of meanings.
To use the engine you will need to create an application and a separate rules assembly.</p>

                <p>
                    The easiest way to see how to do this is to try
 the <a href="https://github.com/IanMercer/AboditNaturalLanguage">'Hello World' sample which you can get from GitHub</a>.
</p>

            </Article>
        );
    }
}