import { Component, Fragment } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface IUIElement {
    name: string,
    id: string,
    text: string | undefined,
    style: string | undefined,
    href: string | undefined,
    children: IUIElement[] | undefined,
    properties: any | undefined
}

export interface IUIComponent {
    obj: IUIElement,
    gsm: any | undefined
}

export const UIComponent = (props: IUIComponent) => {
    const gsm = (e: any) => {
        console.log('gsm(', props.obj.href);
        e.preventDefault();
        if (props.gsm) {
            props.gsm(props.obj.href);
        }
    }

    const obj = props.obj;

    if (!obj) return (<Fragment />);

    obj.children = obj.children || [];

    const style = obj.style;
    if (style) {
        obj.properties['style'] = style;
    }

    switch (obj.name) {
        case 'div': {
            return (
                // TODO: How to map properties
                <div {...obj.properties}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={props.gsm} ></UIComponent>)}
                </div>
            );
        }

        case 'span': {
            return (
                <span {...obj.properties}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={props.gsm} ></UIComponent>)}
                </span>
            );
        }

        case 'table': {
            return (
                <table {...obj.properties}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={props.gsm} ></UIComponent>)}
                </table>
            );
        }

        case 'thead': {
            return (
                <thead {...obj.properties}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={props.gsm} ></UIComponent>)}
                </thead>
            );
        }

        case 'tbody': {
            return (
                <tbody {...obj.properties}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={props.gsm} ></UIComponent>)}
                </tbody>
            );
        }

        case 'tr': {
            return (
                <tr {...obj.properties}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={props.gsm} ></UIComponent>)}
                </tr>
            );
        }

        case 'td': {
            return (
                <td {...obj.properties}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={props.gsm} ></UIComponent>)}
                </td>
            );
        }

        case 'p': {
            return (
                <p {...obj.properties}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={props.gsm} ></UIComponent>)}
                </p>
            );
        }

        case 'nlp': {
            return (
                <a {...obj.properties} href={obj.href} onClick={props.gsm}>
                    {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={{}} ></UIComponent>)}
                </a>
            );
        }

        case 'link': return (
            <Link {...obj.properties} to={obj.href}>
                {obj.children.map((child, i) => <UIComponent obj={child} key={child.id} gsm={{}} ></UIComponent>)}
            </Link>
        );

        case 'b': return (<b>{obj.text}</b>);
        case 'i': return (<i>{obj.text}</i>);
        case 'plain': return (<span {...obj.properties}>{obj.text}</span>);
        case 'code': return (<code {...obj.properties}>{obj.text}</code>);

        default:
            if (obj.name) {
                return (
                    <Fragment>
                        <p>No such element `{obj.name}`</p>
                    </Fragment>
                );
            }
            else return (<Fragment />);
    }
}