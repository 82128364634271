import React, { Component } from 'react';
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom';
import { Article } from '../components/Article'

export class Lexemes extends Component {
  static displayName = Lexemes.name;

  render () {
    return (
      <Article title="Lexemes" aside="Features">

<Helmet>
    <meta name="description" content="AboditNLP works with meanings or 'lexemes' not just words and can recognize synonyms." />
    <title>Lexemes are a unit of meaning</title>
</Helmet>


<p>Common words are represented as Lexemes (units of meaning).  A single word like <em>&#39;run&#39;</em>, for example, 
can have many different meanings:</p>
<p>
<strong>Verb</strong>: <em>to go quickly by moving the legs more rapidly than at a walk 
  and in such a manner that for an instant in each step all or both feet are off the ground.</em>
<br /><strong>Verb</strong>: <em>to move with haste; act quickly: Run home to get some food.</em>
<br /><strong>Noun</strong>: <em>an act or instance, or a period of running</em>
<br /><strong>Noun</strong>: <em>a hurrying to or from some point, as on an errand</em>
</p>
<p>Each of these meanings can be modeled as a separate Lexeme (even though they have the same spelling).  The appropriate meaning to use will be selected
based on the context of the word in relation to the other words around it and the defined sentences that you want to recognize.  That&#39;s not to say the engine
can distinguish all the possible meanings for any word - that would require an inordinate number of rules - but for simple cases like Noun vs Verb it works.</p>
<p>Typically you wouldn&#39;t define all of these dictionary meanings because we are only concerned with a subset of them, but it is fairly common to define both a noun-form
and a verb-form of some words so that they can be recognized by different sentence rules.</p>
<p>Each lexeme uses .NET interfaces to define all of the various <em>classes of meanings</em> (e.g. Verb, PresentTense, ExerciseVerb, VerbFormOfLocomotion, ...) 
or a specific <em>instance of meaning</em> (e.g. Verb.running) that may be placed on it.  You can define your own categories and apply them to Lexemes you create.
By convention classes are capitalized and instances are lower-cased (even though each is represented by using interfaces).</p>
<p>Lexemes exist as part of a <Link to="/features/word-network">network of related lexemes</Link>.

</p>
<h2 id="interfaces-allow-multiple-inheritance-overlapping-classes-of-meanings-">Interfaces allow multiple inheritance 
(overlapping classes of meanings)</h2>
<p>Unlike .NET classes, the classes of meanings present in human languages do not fall into a single hierarchy; rather they form 
  a set of multiple overlapping hierarchies.<br />By using interfaces the engine is able to model the multiple-inheritance present
   in languages: a single lexeme might simultaneously be many 
different things: it might have a tense (Verb.Tense.Past, Verb.Tense.Present, Verb.Tense.Future, ...), 
it might be classified as Verb.Type.Active or Verb.Type.Passive;
it might be a verb that expects an animate object (e.g. a person) as the subject; it 
might be a Verb.Type.Regular verb or an irregular verb; ...  </p>

<p>Each of these different categories can be represented using .NET interfaces, and those interfaces may be placed in their own hierarchies.</p>
<p>By using interfaces to represent the multiple-inheritance in languages this allows a single <Link to="/features/sentences">sentence rule</Link> to recognize either
a single lexeme or perhaps hundreds or thousands of different lexemes that can all be used in a sentence of that form.</p>
</Article>
    );
  }
}
