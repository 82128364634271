import React, { Component } from 'react';
import { Article } from '../components/Article';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export class CustomerService extends Component {
  static displayName = CustomerService.name;

  render () {
    return (
      <Article title="Customer Service" aside="Applications">
<p>Using this engine you can add &#39;chatbot&#39; capabilities to your web site
or create a twitter autoresponder that recognizes and responds to common questions.
</p>
<p>You can use this capability in both a pre-sales and a post-sales role.  In a pre-sales role customers
might be able to ask about products and their availability.</p>
<p>In a post-sales or internal support application the dialog might look like:-</p>
<SyntaxHighlighter style={docco} language="plaintext">
{`
  System  Package for order number 0234 shipped to you today
  User    What is the invoice number?
  System  The invoice number is I986 for $5,678.90
  User    Please email me a copy
  System  I emailed a copy to your work email address.
`}
</SyntaxHighlighter>
</Article>
    );
  }
}
