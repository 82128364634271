import * as ACTION from '../actions/actionTypes';
import { combineReducers } from 'redux';

export interface IDemoState {
    input: string,
    expression: string,
    placeholder: string,
    table: any
}

export interface IActionString {
    type: string,
    value: string
}

export interface IActionObject {
    type: string,
    value: any
}

export function input_reducer(state = '', action: IActionString) {
    switch (action.type) {
        case ACTION.INPUT_UPDATE:
            return action.value;
        default:
            return state;
    }
}

export function placeholder_reducer(state = 'enter an english expression', action: IActionString) {
    switch (action.type) {
        case ACTION.PLACEHOLDER_UPDATE:
            return action.value;
        default:
            return state;
    }
}

export function expression_reducer(state = '', action: IActionString) {
    switch (action.type) {
        case ACTION.EXPRESSION_UPDATE:
            return action.value;
        default:
            return state;
    }
}

export function table_reducer(state = {}, action: IActionObject) {
    switch (action.type) {
        case ACTION.TABLE_UPDATE:
            return action.value;
        default:
            return state;
    }
}

export const demo_reducer = combineReducers({
    input: input_reducer,
    expression: expression_reducer,
    placeholder: placeholder_reducer,
    table: table_reducer
});

export default demo_reducer;