import React, { Component } from 'react';
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { UIComponent } from '../components/UIComponent';
import { MyInput } from '../components/myinput';

export class Chat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: '',
            message: '',
            messages: [],
            hubConnection: null
        };

        this.index = 1000;

        this.sendMessage = this.sendMessage.bind(this);
        this.display = this.display.bind(this);
        this.appendLine = this.appendLine.bind(this);
        this.gsm = this.gsm.bind(this);

        this.appInsights = props.appInsights;
    }

    display(returnMessage) {
        const target = returnMessage.target;
        console.log('Received', returnMessage);
        if (target === "") {
            this.appendLine(returnMessage);
        } else {
            console.log(target);
            //$('#' + id).html(text);
        }
    }

    appendLine(returnMessage) {
        const messages = this.state.messages.concat([returnMessage]);
        if (messages.length > 20) {
            messages.shift();
        }
        this.setState({ messages: messages });
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" });
        }
    }

    sendMessage(message) {
        this.index++;

        this.appendLine({
            sender: "user", component:
            {
                name: "p", id: this.index.toString(), properties: {}, children: [
                    { name: "plain", id: this.index.toString(), text: message }
                ]
            }
        });

        this.state.hubConnection
            .invoke('send', message)
            .catch(err => console.error(err));

        this.setState({ message: '' });

        if (this.appInsights) {
            this.appInsights.trackTrace(message);
        }
        else {
            console.log("Error, app insights not initialized");
        }
    };

    gsm(message) {
        this.sendMessage(message);
    };

    componentDidMount = () => {
        const hubConnection = new HubConnectionBuilder()
            .withUrl("/chat")
            .configureLogging(LogLevel.Information)
            .build();

        this.setState({ hubConnection }, () => {
            this.state.hubConnection
                .start()
                .then(() => {
                    console.log('Connection started!')
                    console.log('Subscribing to stream of updates');
                    this.state.hubConnection.stream("GetStream").subscribe({
                        next: this.display,
                        error: function (err) {
                            console.error("stream error", err);
                        }
                    });
                })
                .catch(err => console.log('Error while establishing connection :('));

            this.state.hubConnection.on('sendToAll', (receivedMessage) => {
                const messages = this.state.messages.concat([receivedMessage]);
                this.setState({ messages: messages });
            });
        });
    };

    render() {
        return (
            <div id="main" className="clearfix maincontent">
                <div className="row">
                    <div className="col-md-9">
                        <article className="post">
                            <div className="entry-content">
                                <div className="chatBox">
                                    <div id="messages" className="chatInner">
                                        {this.state.messages.map((message, index) => (
                                            <div className="chatline" key={message.component.id}>
                                                <div className={"chatInner " + message.sender}>
                                                    <UIComponent obj={message.component} gsm={this.gsm} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ float: "left", clear: "both" }}
                                        ref={(el) => { this.messagesEnd = el; }}>
                                    </div>
                                </div>
                                <MyInput value={this.state.input} onChange={this.sendMessage} placeholder="..." />
                            </div>
                        </article>
                    </div>
                    <aside id="sidebar" className="widget col-md-3">
                        <p> This demo shows off some features of the Abodit Natural Language Engine.</p>
                        <p>
                            It's a guided demo where the server will step you through some different
                            aspects of the demonstration. It also shows off multi-modal input (text or web) and
                            multi-modal output (text, images and web updates).
                        </p>
                        <p>
                            Your own application doesn't need to be this complex, it could be as simple as a text box
                            taking input for a natural language query based on times.
                        </p>
                        <p> Although the demo steps you through a few scenarios you can type anything at any time.</p>
                        <p> Don't forget to try a couple of requests to 'define' a word so you can see the large vocabulary that Abodit NLP has.</p>
                    </aside>
                </div>

                <br />

            </div>
        );
    }

    /*
    //    chathandler() {
    //    const connection = new signalR.HubConnectionBuilder()
    //        .withUrl("/chat")
    //        // .configureLogging(signalR.LogLevel.Trace)
    //        .build();

    //    function sendMessage() {
    //        var message = $('#msg').val();
    //        appendLine('user', message);
    //        $('#msg').val(''); // clear it
    //        console.log('Sending message', message);
    //        window.setTimeout(function () { connection.invoke('send', message); }, 500);
    //    }

    //    // ick: pass this function out for calling from onClick events
    //    var gsm = function (message) {
    //        appendLine('user', message);
    //        $('#msg').val(''); // clear it
    //        window.setTimeout(function () { connection.invoke('send', message); }, 500);
    //    };

    //    function startStream() {
    //        //console.log('Sending hello message');
    //        //connection.invoke('send', 'Hello');

    //        console.log('Subscribing to stream of updates');
    //        connection.stream("GetStream").subscribe({
    //            next: display,
    //            error: function (err) {
    //                console.error("stream error", err);
    //            }
    //        });
    //    }

    //    $("#broadcast").click(function () {
    //        // Call the chat method on the server
    //        var message = $('#msg').val();
    //        console.log('Send message', message);
    //        sendMessage();
    //    });

    //    $('#msg').bind('keypress', function (e) {
    //        var code = e.keyCode ? e.keyCode : e.which;
    //        if (code === 13) { //Enter keycode
    //            sendMessage();
    //            //                        return false;       // swallow it
    //            return true;
    //        }
    //    });

    //    // Start the connection
    //    connection.start()
    //        .then(() => startStream());

    //    //	appendLine('system', 'Type "hello" to get started');

    //    return gsm;
    //};
    */
}