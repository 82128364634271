import React, { Component } from 'react';
import { Helmet } from "react-helmet"
//import { Link } from 'react-router-dom';
import { Article } from '../components/Article'

export class Metamodel extends Component {
  static displayName = Metamodel.name;

  render () {
    return (
        <Article title="Meta models" aside="Features">

<Helmet>
    <meta name="description" content="Meta models describe the contents of a data source including the units used for each column." />
    <title>Meta models for NLP</title>
</Helmet>

<p>AboditNLP can understand complex entity queries like <code>products under 20kg</code> or 
<code>products wider than 20cm</code> or <code>people aged over 30</code>. 
It can turn these queries into SQL queries, object queries, or queries against other database technologies like MongoDB.</p>
<p>To facilitate this you can pass a metamodel to the engine describing the entities and fields and
it will then build query expressions referencing those entities and fields. These queries may also include
a sort order, e.g. <code>longest products over 20m</code>.</p>
<p>In addition to fields you can define expressions combining multiple fields (e.g. <code>Area = Width * Length</code>) which
then allows users to query for, say, <code>products over 10 hectares</code>.</p>
<p>Query expressions can also operate on boolean values (e.g. <code>Price &lt; 20</code> could be defined as &#39;cheap&#39;) and can
map them to words or expressions.</p>
<p>The metamodel definition of fields and expressions can also define the units of measure to be applied
for each. For example, you can define that a &#39;Width&#39; field is measured in <code>cm</code>. When a user queries by, say,
inches, AboditNLP will automatically perform all the necessary unit conversions to build the query.</p>

        </Article>
    );
  }
}
